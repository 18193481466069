import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: 'EarnForex',
    },
    component: () => import("../views/Home.vue"),
    beforeEnter: (to, from, next) => {
      localStorage.getItem("Mode") ? next({ name: "brokers" }) : next();
    },
  },
  {
    path: "/logout",
    name: "notLogined",
    component: () => import("../views/NotLogined.vue"),
  },

  //  auth
  {
    path: "/manager-login",
    name: "adminLogin",
    meta: {
      title: 'Login | EarnForex',
    },
    component: () => import("../views/AdminLogin.vue"),
    redirect:
      localStorage.getItem("Mode") && localStorage.getItem("login")
        ? "brokers"
        : null,
  },
  {
    path: "/broker/creating_link",
    name: "createByLink",
    meta: {
      title: 'Create broker | EarnForex',
    },
    component: () => import("../views/CreateLink.vue"),
  },
  {
    path: "/broker/updating_link",
    name: "udpateByLink",
    meta: {
      title: 'Create broker | EarnForex',
    },
    component: () => import("../views/UpdatingLink.vue"),
  },

  // end auth

  //  main
  {
    path: "/brokers",
    name: "brokers",
    meta: {
      auth: true,
      title: 'Brokers | EarnForex',
    },
    component: () => import("../views/Brokers.vue"),
  },
  {
    path: "/broker/add_broker",
    name: "brokerCreate",
    meta: {
      auth: true,
      title: 'Create broker | EarnForex',
    },
    component: () => import("../views/BrokerForm.vue"),
  },
  {
    path: "/edit_broker/:id",
    name: "brokerEdit",
    meta: {
      auth: true,
      title: 'Broker page | EarnForex',
    },
    component: () => import("../views/BrokerForm.vue"),
  },
  {
    path: "/broker/review/:id",
    name: "brokerReview",
    meta: {
      auth: true,
      title: 'Broker review | EarnForex',
    },
    component: () => import("../views/BrokerForm.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      auth: true,
      title: 'Update screenshots | EarnForex',
    },
    component: () => import("../views/Settings.vue"),
  },
  // end main
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const userType = localStorage.getItem("Mode");
  const needAuth = to.matched.some((rout) => rout.meta.auth);

  document.title = to.meta.title;

  if (!userType && needAuth) {
    next({ name: "home" });
  } else {
    next();
  }
});

export default router;
