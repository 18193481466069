import _ from "lodash";
import Vue from "vue";

export default {
  actions: {},
  mutations: {
    updateSimpleFields(state, data) {
      if (_.has(state.accountType, data.propName)) {
        state.accountType[data.propName] = data.data;
      }
      if (_.has(state.accountType, "__typename")) {
        delete state.accountType._typename;
      }
    },
    updateScalpingExplanations(state, data) {
      if (!data.id) {
        delete data.id;
        state.accountType.scalpingExplanations.push(data);
      } else {
        let oldDataIndex = state.accountType.scalpingExplanations.findIndex(
          (item) => item.id == data.id
        );
        if (oldDataIndex > -1) {
          state.accountType.scalpingExplanations[oldDataIndex].description =
            data.description;
        } else {
          state.accountType.scalpingExplanations.push(data);
        }
      }
    },
    updateEaExplanations(state, data) {
      if (!data.id) {
        delete data.id;
        state.accountType.eaExplanations.push(data);
      } else {
        let oldDataIndex = state.accountType.eaExplanations.findIndex(
          (item) => item.id == data.id
        );
        if (oldDataIndex > -1) {
          state.accountType.eaExplanations[oldDataIndex].description =
            data.description;
        } else {
          state.accountType.eaExplanations.push(data);
        }
      }
    },
    updateSpesialConditions(state, data) {
      if (!data.id) {
        delete data.id;
        state.accountType.accountTypeSpecialConditions.push(data);
      } else {
        let oldDataIndex =
          state.accountType.accountTypeSpecialConditions.findIndex(
            (item) => item.id === data.id
          );
        if (oldDataIndex > -1) {
          state.accountType.accountTypeSpecialConditions[
            oldDataIndex
          ].description = data.description;
        } else {
          state.accountType.accountTypeSpecialConditions.push(data);
        }
      }
    },
    updateAccountTypeInstruments(state, data) {
      state.accountType.accountTypeInstruments = data;
    },
    updateSingleInstrument(state, data) {
      let index = state.accountType.accountTypeInstruments.findIndex(
        (item) => item.instrumentId === data.instrumentId
      );
      if (data.kindLeverage === "FIXED") {
        delete data.accountTypeInstrumentPairs;
      } else {
        delete data.leverage;
        if (data?.accountTypeInstrumentPairs?.length) {
          data.accountTypeInstrumentPairs.map((item) => {
            return Object.assign(item, {
              maxPosition: item.maxPosition ? +item.maxPosition : null,
              minPosition: item.minPosition ? +item.minPosition : null,
              leverage: item.leverage ? +item.leverage : null,
            });
          });
        }
      }
      if (index >= 0) {
        state.accountType.accountTypeInstruments[index] = data;
      } else {
        state.accountType.accountTypeInstruments.push(data);
      }
    },
    setingOldAccountTypeData(state, data) {
      state.accountType = Object.assign(state.accountType, data);
    },
    removeAccountTypeTypenames(state) {
      if (state.accountType != undefined) {
        delete state.accountType.__typename;

        for (var key in state.accountType) {
          if (
            typeof state.accountType[key] == "object" &&
            state.accountType[key] != null
          ) {
            state.accountType[key].forEach((item) => {
              delete item.__typename;
            });
          }
        }
      }
    },
    preparingAccountTypeEnum(state) {
      state.accountType.centAccount = state.accountType.centAccount
        ? state.accountType.centAccount.toUpperCase()
        : "NO";
      state.accountType.digitsAfterDot = state.accountType.digitsAfterDot
        ? state.accountType.digitsAfterDot.toUpperCase()
        : "FOUR";
      state.accountType.interestRate = state.accountType.interestRate
        ? state.accountType.interestRate.toUpperCase()
        : "NO";
      state.accountType.hedgingAllowed = state.accountType.hedgingAllowed
        ? state.accountType.hedgingAllowed.toUpperCase()
        : "NO";
      state.accountType.eaAllowed = state.accountType.eaAllowed
        ? state.accountType.eaAllowed.toUpperCase()
        : "UNKNOWN";
      state.accountType.scalpingAllowed = state.accountType.scalpingAllowed
        ? state.accountType.scalpingAllowed.toUpperCase()
        : "UNKNOWN";

      state.accountType.executionMethod =
        state.accountType.executionMethod === "null" ||
        state.accountType.executionMethod === null ||
        !state.accountType.executionMethod.length
          ? null
          : state.accountType.executionMethod.toUpperCase();
    },
    settingAccountTypesDatatoReviewed(state, obj) {
      if (obj.type === "scalpingLimits") {
        let index = state.accountType.scalpingExplanations.findIndex(
          (item) =>
            item.languageSlug.toLowerCase() === obj.languageSlug.toLowerCase()
        );
        if (index > -1) {
          state.accountType.scalpingExplanations[index][obj.name] = obj.value;
        } else {
          state.accountType.scalpingExplanations.push({
            description: obj.value,
            id: obj.id,
            languageSlug: obj.languageSlug,
          });
        }
      } else if (obj.type === "eaLimits") {
        let index = state.accountType.eaExplanations.findIndex(
          (item) =>
            item.languageSlug.toLowerCase() === obj.languageSlug.toLowerCase()
        );
        if (index > -1) {
          state.accountType.eaExplanations[index][obj.name] = obj.value;
        } else {
          state.accountType.eaExplanations.push({
            description: obj.value,
            id: obj.id,
            languageSlug: obj.languageSlug,
          });
        }
      } else if (obj.type === "specialConditions") {
        let index = state.accountType.accountTypeSpecialConditions.findIndex(
          (item) =>
            item.languageSlug.toLowerCase() === obj.languageSlug.toLowerCase()
        );
        if (index > -1) {
          state.accountType.accountTypeSpecialConditions[index][obj.name] =
            obj.value;
        } else {
          state.accountType.accountTypeSpecialConditions.push({
            description: obj.value,
            id: obj.id,
            languageSlug: obj.languageSlug,
          });
        }
      } else if (obj.type === "licenseNumber" && obj.instrumentId) {
        let index = state.accountType.accountTypeInstruments.findIndex(
          (item) => parseInt(item.instrumentId) === parseInt(obj.instrumentId)
        );
        if (index < 0) {
          if (obj.maxLeverage !== "" && obj.maxLeverage !== null) {
            state.accountType.accountTypeInstruments.push({
              instrumentId: obj.instrumentId,
              leverage: parseFloat(obj.leverage),
              instrumentName: obj.instrumentName,
              typeLeverage: obj.kindLeverage,
              accountTypeInstrumentPairs: obj.accountTypeInstrumentPairs || [],
              rollback: Object.hasOwn(obj, "rollback") ? obj.rollback : false,
            });
          }
        } else if (index > -1) {
          let target = state.accountType.accountTypeInstruments[index];
          target.instrumentId = obj.instrumentId;
          target.leverage = parseFloat(obj.leverage);
          target.instrumentName = obj.instrumentName;
          target.kindLeverage = obj.kindLeverage;
          target.accountTypeInstrumentPairs =
            obj.accountTypeInstrumentPairs || [];
          target.rollback = Object.hasOwn(obj, "rollback")
            ? obj.rollback
            : false;
        }
      } else if (obj.type === "currencyPair") {
        if (obj.id === "newLogItem") {
          let index = state.accountType.accountTypeCurrencyPairs.findIndex(
            (item) => item.nameCurrencyPair === obj.nameCurrencyPair
          );
          if (index >= 0) {
            state.accountType.accountTypeCurrencyPairs.splice(index, 1);
          }
          return;
        }
        let index = state.accountType.accountTypeCurrencyPairs.findIndex(
          (item) => parseInt(item.id) === parseInt(obj.id)
        );
        if (index >= 0) {
          let target = state.accountType.accountTypeCurrencyPairs[index];

          if (obj.nameCurrencyPair) {
            target.nameCurrencyPair = obj.nameCurrencyPair;
            target.id = parseInt(obj.id);
            target.kindSpread = obj.kindSpread;
            target.typeSpread = obj.typeSpread;
            target.lowestSpread = parseFloat(obj.lowestSpread);
            target.typicalSpread = parseFloat(obj.typicalSpread);
          } else {
            state.accountType.accountTypeCurrencyPairs.splice(index, 1);
          }
        } else {
          state.accountType.accountTypeCurrencyPairs.push({
            nameCurrencyPair: obj.nameCurrencyPair,
            id: parseFloat(obj.id),
            kindSpread: obj.kindSpread,
            typeSpread: obj.typeSpread,
            lowestSpread: parseFloat(obj.lowestSpread),
            typicalSpread: parseFloat(obj.typicalSpread),
          });
        }
      } else {
        if (obj.name !== "" && _.has(state.accountType, obj.name)) {
          state.accountType[obj.name] = obj.value;
        }
      }
    },
    settingAccountDataReviewField(state, data) {
      state.accountType.reviewed = data;
    },
    preparingTradingPlaforms(state) {
      state.accountType.tradingPlatforms
        ? (state.accountType.tradingPlatforms =
            state.accountType.tradingPlatforms.map((item) =>
              typeof item === "object"
                ? (item = parseInt(item.id))
                : (item = parseInt(item))
            ))
        : null;
    },
    preparingInstruments(state) {
      state.accountType.accountTypeInstruments.forEach((item) => {
        Vue.delete(item, "instrumentName");
        if (item.kindLeverage === "FIXED") {
          Vue.delete(item, "accountTypeInstrumentPairs");
        }
      });
    },
    preparingNumberInputs(state) {
      state.accountType.minAccountSize = isNaN(
        parseFloat(state.accountType.minAccountSize)
      )
        ? null
        : parseFloat(state.accountType.minAccountSize);
      state.accountType.maxAccountSize = isNaN(
        parseFloat(state.accountType.maxAccountSize)
      )
        ? null
        : parseFloat(state.accountType.maxAccountSize);
      state.accountType.minPositionSize = isNaN(
        parseFloat(state.accountType.minPositionSize)
      )
        ? null
        : parseFloat(state.accountType.minPositionSize);
      state.accountType.commission = isNaN(
        parseFloat(state.accountType.commission)
      )
        ? null
        : parseFloat(state.accountType.commission);
      state.accountType.commissionCfd = isNaN(
        parseFloat(state.accountType.commissionCfd)
      )
        ? null
        : parseFloat(state.accountType.commissionCfd);
      state.accountType.marginCallLevel = isNaN(
        parseFloat(state.accountType.marginCallLevel)
      )
        ? null
        : parseFloat(state.accountType.marginCallLevel);
      state.accountType.stopOutLevel = isNaN(
        parseFloat(state.accountType.stopOutLevel)
      )
        ? null
        : parseFloat(state.accountType.stopOutLevel);
      state.accountType.minDistanceToStopOrders = isNaN(
        parseFloat(state.accountType.minDistanceToStopOrders)
      )
        ? null
        : parseFloat(state.accountType.minDistanceToStopOrders);
      state.accountType.minDistanceToLimitOrders = isNaN(
        parseFloat(state.accountType.minDistanceToLimitOrders)
      )
        ? null
        : parseFloat(state.accountType.minDistanceToLimitOrders);
      state.accountType.currencyPairsQuantity = isNaN(
        parseFloat(state.accountType.currencyPairsQuantity)
      )
        ? null
        : parseFloat(state.accountType.currencyPairsQuantity);
      state.accountType.exoticCurrencyPairsQuantity = isNaN(
        parseFloat(state.accountType.exoticCurrencyPairsQuantity)
      )
        ? null
        : parseFloat(state.accountType.exoticCurrencyPairsQuantity);
      state.accountType.maxPositionsSimultaneous = isNaN(
        parseFloat(state.accountType.maxPositionsSimultaneous)
      )
        ? null
        : parseFloat(state.accountType.maxPositionsSimultaneous);
    },
    removeDeletingMode(state) {
      if (state.accountType !== undefined) {
        delete state.accountType.deletingMode;
      }
    },
    isAccountTypesChangedDetect(state, boolean) {
      state.isChanged = boolean;
    },
    preparingOperatingSystems(state) {
      state.accountType.operatingSystems = state.accountType.operatingSystems
        ? state.accountType.operatingSystems.map((item) =>
            typeof item !== "number" ? +item.id : item
          )
        : [];
    },
    resetingAccount(state) {
      state.accountType = null;
      state.accountType = {
        id: "",
        name: "",
        minAccountSize: "",
        maxAccountSize: "",
        minPositionSize: "",
        commission: "",
        commissionCfd: "",
        marginCallLevel: "",
        stopOutLevel: "",
        minDistanceToStopOrders: "",
        minDistanceToLimitOrders: "",
        scalpingAllowed: null,
        scalpingExplanations: [],
        eaAllowed: null,
        eaExplanations: [],
        hedgingAllowed: null,
        interestRate: null,
        digitsAfterDot: null,
        centAccount: null,
        currencyPairsQuantity: "",
        exoticCurrencyPairsQuantity: "",
        accountTypeSpecialConditions: [],
        tradingPlatforms: [],
        accountTypeInstruments: [],
        reviewed: "NONE",
        executionMethod: "",
        maxPositionsSimultaneous: null,
        automated: false,
        api: "",
        web_based: "",
        oneClick: false,
        oco: false,
        pendingOrders: false,
        trailingStop: false,
        operatingSystems: [],
        accountTypeCurrencyPairs: [],
      };
    },
    preparingBooleanFields(state) {
      state.accountType.automated =
        state.accountType.automated === "true" ||
        state.accountType.automated === true;
      state.accountType.oneClick =
        state.accountType.oneClick === "true" ||
        state.accountType.oneClick === true;
      state.accountType.oco =
        state.accountType.oco === "true" || state.accountType.oco === true;
      state.accountType.pendingOrders =
        state.accountType.pendingOrders === "true" ||
        state.accountType.pendingOrders === true;
      state.accountType.trailingStop =
        state.accountType.trailingStop === "true" ||
        state.accountType.trailingStop === true;
    },

    createNewCurrencyPair(state, data) {
      state.accountType.accountTypeCurrencyPairs.push(data);
    },
    updateCurrencyPairs(state, data) {
      if (data.nameCurrencyPair) {
        let index = state.accountType.accountTypeCurrencyPairs.findIndex(
          (item) => item.nameCurrencyPair === data.nameCurrencyPair
        );
        if (index >= 0) {
          state.accountType.accountTypeCurrencyPairs[index] = data;
        } else {
          if (data.nameCurrencyPair) {
            state.accountType.accountTypeCurrencyPairs.push(data);
          }
        }
      }
    },
    updateSingleCurrencyPair(state, data) {
      let index = state.accountType.accountTypeCurrencyPairs.findIndex(
        (item) => item.id === data.id
      );
      if (index >= 0) {
        state.accountType.accountTypeCurrencyPairs[index].currencyPair =
          data.currencyPair;
        state.accountType.accountTypeCurrencyPairs[index].kindSpread =
          data.kindSpread;
        state.accountType.accountTypeCurrencyPairs[index].lowestSpread =
          data.lowestSpread;
        state.accountType.accountTypeCurrencyPairs[index].typeSpread =
          data.typeSpread;
        state.accountType.accountTypeCurrencyPairs[index].typicalSpread =
          data.typicalSpread;
      } else {
        state.accountType.accountTypeCurrencyPairs.push(data);
      }
    },
    removeCurrencyPair(state, id) {
      let index = state.accountType.accountTypeCurrencyPairs.findIndex(
        (item) => item.id === id
      );

      state.accountType.accountTypeCurrencyPairs.splice(index, 1);
    },

    preparingCurrencyPairs(state) {
      state.accountType.accountTypeCurrencyPairs.forEach((item) => {
        if (isNaN(item.id)) {
          delete item.id;
        }
        delete item.currencyPairName;
        Object.keys(item).forEach((key) => {
          if (!isNaN(item[key])) {
            if (typeof item[key] === "string") {
              item[key] = parseFloat(item[key]);
            }
          }
        });
      });
    },
    deletingEmptyCurrencyPairs(state) {
      Vue.delete(
        state.accountType.accountTypeCurrencyPairs,
        state.accountType.accountTypeCurrencyPairs.findIndex(
          (item) => !item.nameCurrencyPair
        )
      );
    },
  },
  state: {
    accountType: {
      id: "",
      name: "",
      minAccountSize: "",
      maxAccountSize: "",
      minPositionSize: "",
      commission: "",
      commissionCfd: "",
      marginCallLevel: "",
      stopOutLevel: "",
      minDistanceToStopOrders: "",
      minDistanceToLimitOrders: "",
      scalpingAllowed: null,
      scalpingExplanations: [],
      eaAllowed: null,
      eaExplanations: [],
      hedgingAllowed: null,
      interestRate: null,
      digitsAfterDot: null,
      centAccount: null,
      currencyPairsQuantity: "",
      exoticCurrencyPairsQuantity: "",
      accountTypeSpecialConditions: [],
      tradingPlatforms: [],
      accountTypeInstruments: [],
      reviewed: "NONE",
      executionMethod: "",
      maxPositionsSimultaneous: null,
      automated: false,
      api: "",
      web_based: "",
      oneClick: false,
      oco: false,
      pendingOrders: false,
      trailingStop: false,
      operatingSystems: [],
      accountTypeCurrencyPairs: [],
    },
    accountTypeDefault: {
      id: "",
      name: "",
      minAccountSize: "",
      maxAccountSize: "",
      minPositionSize: "",
      commission: "",
      commissionCfd: "",
      marginCallLevel: "",
      stopOutLevel: "",
      minDistanceToStopOrders: "",
      minDistanceToLimitOrders: "",
      scalpingAllowed: null,
      scalpingExplanations: [],
      eaAllowed: null,
      eaExplanations: [],
      hedgingAllowed: null,
      interestRate: null,
      digitsAfterDot: null,
      centAccount: null,
      currencyPairsQuantity: "",
      exoticCurrencyPairsQuantity: "",
      accountTypeSpecialConditions: [],
      tradingPlatforms: [],
      accountTypeInstruments: [],
      reviewed: "NONE",
      executionMethod: "",
      maxPositionsSimultaneous: null,
      automated: false,
      api: "",
      web_based: "",
      oneClick: false,
      oco: false,
      pendingOrders: false,
      trailingStop: false,
      operatingSystems: [],
      accountTypeCurrencyPairs: [],
    },
  },
  getters: {
    getAccountTypeDefault(state) {
      return state.accountTypeDefault;
    },
    getAccountType(state) {
      return state.accountType;
    },
  },
};
