import Vue from "vue";
import _ from "lodash";

export default {
  actions: {},
  mutations: {
    addHistoryItem(state, obj) {
      state.brokerData.brokerHistory = obj;
    },
    createNewHistoryItem(state, obj) {
      state.brokerData.brokerHistory.forEach((item) => {
        if (item.languageSlug === obj.lang) {
          item.data.push(obj.data);
        }
      });
    },
    removeHistoryItem(state, obj) {
      let index = state.brokerData.brokerHistory.findIndex(
        (i) => i.languageSlug === obj.lang
      );
      if (index !== -1) {
        let item = state.brokerData.brokerHistory[index].data.findIndex(
          (item) => item.id === obj.id
        );
        state.brokerData.brokerHistory[index].data.splice(item, 1);
      }
    },
    setHistoryItem(state, obj) {
      state.brokerData.brokerHistory.forEach((list) => {
        if (list.languageSlug === obj.lang) {
          let item = list.data.findIndex((item) => item.id === obj.id);
          Vue.set(list.data, item, {
            text: obj.text,
            date: obj.date,
            id: obj.id,
          });
        }
      });
    },
    editBrokerHistory(state, history) {
      delete history.__typename;
      history.forEach((item) => {
        delete item.__typename;
        if (item.data !== undefined) {
          item.data.forEach((dataItem) => {
            delete dataItem.__typename;
          });
        }
      });

      state.brokerData.brokerHistory = history;
    },
    updateMainData(state, data) {
      if (!data.id) {
        delete data.id;
        state.brokerData.mainData.push(data);
      } else {
        let oldDataIndex = state.brokerData.mainData.findIndex(
          (item) => item.id == data.id
        );
        if (oldDataIndex > -1) {
          state.brokerData.mainData[oldDataIndex].name = data.name;
          state.brokerData.mainData[oldDataIndex].languageSlug =
            data.languageSlug;
          state.brokerData.mainData[oldDataIndex].link = data.link;
          state.brokerData.mainData[oldDataIndex].externalLink =
            data.externalLink;
          state.brokerData.mainData[oldDataIndex].disabled = data.disabled;
          state.brokerData.mainData[oldDataIndex].id = parseInt(data.id);
        } else {
          state.brokerData.mainData.push(data);
        }
      }

      let englishFields = state.brokerData.mainData.filter(
        (item) => item.languageSlug === "english"
      );

      state.brokerData.mainData.forEach((item) => {
        Object.keys(item).forEach((field) => {
          if (item.languageSlug != "english") {
            item[field] =
              item[field] == "" && field != "disabled"
                ? englishFields[0][field]
                : item[field];
          }
        });
      });
    },
    updateBrokerLicenses(state, data) {
      state.brokerData.brokerLicenses = data;
    },
    updateSingleLicense(state, data) {
      let index = state.brokerData.brokerLicenses.findIndex(
        (item) => item.licenseId === data.licenseId
      );
      if (index >= 0) {
        state.brokerData.brokerLicenses[index].licenseNumber =
          data.licenseNumber;
      } else {
        state.brokerData.brokerLicenses.push(data);
      }
    },
    updateBrokerInfo(state, obj) {
      if (_.has(state.brokerData, obj.propName)) {
        state.brokerData[obj.propName] = obj.data;
      }
      if (_.has(state.brokerData, "__typename")) {
        delete state.brokerData._typename;
      }
    },
    addDemoPlatformItem(state, item) {
      state.brokerData.brokerDemoPlatforms.push(item);
    },
    removeDemoPlaform(state, platformId) {
      let item = state.brokerData.brokerDemoPlatforms.findIndex(
        (item) => item.id === platformId
      );
      state.brokerData.brokerDemoPlatforms.splice(item, 1);
    },
    updateDemoPlataformItem(state, obj) {
      let item = state.brokerData.brokerDemoPlatforms.findIndex(
        (item) => item.id === obj.id
      );

      state.brokerData.brokerDemoPlatforms[item] = {
        demoTradingPlatformCondition: obj.demoTradingPlatformCondition,
        demoTradingPlatformConditionDays: isNaN(
          parseInt(obj.demoTradingPlatformConditionDays)
        )
          ? null
          : parseInt(obj.demoTradingPlatformConditionDays),
        demoTradingPlatformId: parseInt(obj.demoTradingPlatformId),
        id: parseInt(obj.id),
      };
      if (
        state.brokerData.brokerDemoPlatforms[
          state.brokerData.brokerDemoPlatforms.length - 1
        ].demoTradingPlatformId == null
      ) {
        state.brokerData.brokerDemoPlatforms.pop();
      }
    },
    updateVipConditions(state, data) {
      if (
        data != undefined &&
        state.brokerData.brokerVipConditions != undefined &&
        (data.id == "" || data.id == undefined)
      ) {
        delete data.id;
        state.brokerData.brokerVipConditions.push(data);
      } else {
        let oldData = state.brokerData.brokerVipConditions.findIndex(
          (item) => item.id == data.id
        );
        if (oldData > -1) {
          state.brokerData.brokerVipConditions[oldData].contractSpecsLink =
            data.contractSpecsLink;
          state.brokerData.brokerVipConditions[oldData].demoAccountLink =
            data.demoAccountLink;
          state.brokerData.brokerVipConditions[oldData].languageSlug =
            data.languageSlug;
          state.brokerData.brokerVipConditions[oldData].description =
            data.description;
          state.brokerData.brokerVipConditions[oldData].realAccountLink =
            data.realAccountLink;
          state.brokerData.brokerVipConditions[oldData].specialConditions =
            data.specialConditions;

          state.brokerData.brokerVipConditions[oldData].id = parseInt(data.id);
        } else {
          state.brokerData.brokerVipConditions.push(data);
        }
      }

      let englishFields = state.brokerData.brokerVipConditions.filter(
        (item) => item.languageSlug === "english"
      );

      state.brokerData.brokerVipConditions.forEach((item) => {
        Object.keys(item).forEach((field) => {
          if (item.languageSlug !== "english") {
            item[field] =
              item[field] === "" && field.includes("Link")
                ? englishFields[0][field]
                : item[field];
          }
        });
      });
    },
    updateBrokerOptions(state, data) {
      state.brokerData.brokerOptions = data;
    },
    updateVipOptions(state, data) {
      state.brokerData.brokerVipConditionOptions = data;
    },
    setingOldBrokerData(state, data) {
      state.brokerData = Object.assign(state.brokerData, data);
    },
    settingDatatoReviewed(state, obj) {
      if (obj.type === "brokerNotes") {
        let index = state.brokerData.brokerNotes.findIndex(
          (item) =>
            item.languageSlug.toLowerCase() === obj.languageSlug.toLowerCase()
        );
        if (index > -1) {
          state.brokerData.brokerNotes[index][obj.name] = obj.value;
        } else {
          state.brokerData.brokerNotes.push({
            description: obj.value,
            id: obj.id,
            languageSlug: obj.languageSlug,
          });
        }
      } else if (obj.type === "brokerWarningSings") {
        let index = state.brokerData.brokerWarningSings.findIndex(
          (item) =>
            item.languageSlug.toLowerCase() === obj.languageSlug.toLowerCase()
        );
        if (index > -1) {
          state.brokerData.brokerWarningSings[index][obj.name] = obj.value;
        } else {
          state.brokerData.brokerWarningSings.push({
            description: obj.value,
            id: obj.id,
            languageSlug: obj.languageSlug,
          });
        }
      } else if (obj.type === "brokerFlags") {
        let index = state.brokerData.brokerFlags.findIndex(
          (item) =>
            item.languageSlug.toLowerCase() === obj.languageSlug.toLowerCase()
        );
        if (index > -1) {
          state.brokerData.brokerFlags[index][obj.name] = obj.value;
        } else {
          state.brokerData.brokerFlags.push({
            flags: obj.value,
            id: obj.id,
            languageSlug: obj.languageSlug,
          });
        }
      } else if (obj.type === "mainData") {
        let index = state.brokerData.mainData.findIndex(
          (item) =>
            item.languageSlug.toLowerCase() === obj.languageSlug.toLowerCase()
        );
        if (index > -1) {
          if (obj.name.includes("disabled")) {
            state.brokerData.mainData[index].disabled =
              obj.value === "true" ? true : false;
          } else {
            state.brokerData.mainData[index][obj.name] = obj.value;
          }
        }
      } else if (obj.type === "vipCondition") {
        let index = state.brokerData.brokerVipConditions.findIndex(
          (item) =>
            item.languageSlug.toLowerCase() === obj.languageSlug.toLowerCase()
        );
        if (index > -1) {
          state.brokerData.brokerVipConditions[index][obj.name] = obj.value;
        }
      } else if (obj.type === "brokerHistory" || obj.type === "history") {
        let updates = {
          date: obj.date,
          id: obj.id,
          text: obj.text,
        };
        let index = state.brokerData.brokerHistory.findIndex(
          (item) =>
            item.languageSlug.toLowerCase() === obj.languageSlug.toLowerCase()
        );
        if (index > -1) {
          let itemToreview = state.brokerData.brokerHistory[
            index
          ].data.findIndex((item) => item.id === obj.id);
          if (itemToreview > -1 && (obj.date !== "" || obj.text !== "")) {
            state.brokerData.brokerHistory[index].data[itemToreview] = updates;
          } else if (index > -1 && (obj.date === "" || obj.text === "")) {
            Vue.delete(
              state.brokerData.brokerHistory[index].data,
              itemToreview
            );
          } else {
            state.brokerData.brokerHistory[index].data.push(updates);
          }
        }
      } else if (
        (obj.type === "licensesSelect" || obj.type === "licenseNumber") &&
        obj.licenseId
      ) {
        if (obj.state === "") return;
        if (obj?.fromApproveChecker) return;
        let index = state.brokerData.brokerLicenses.findIndex(
          (item) => parseInt(item.licenseId) === parseInt(obj.licenseId)
        );
        if (index < 0 && !obj.isDeleted) {
          state.brokerData.brokerLicenses.push({
            licenseId: obj.licenseId,
            licenseNumber: obj.value,
          });
        } else if (obj.isDeleted) {
          Vue.delete(state.brokerData.brokerLicenses, index);
        } else if (index > -1 && obj.value !== "" && obj.value !== null) {
          state.brokerData.brokerLicenses[index].licenseNumber = obj.value;
        } else if (index > -1 && (obj.value === "" || obj.value === null)) {
          Vue.delete(state.brokerData.brokerLicenses, index);
        }
      } else if (obj.type === "brokerOption" && obj.id) {
        let index = state.brokerData.brokerOptions.findIndex(
          (item) => parseInt(item.id) === parseInt(obj.id)
        );
        if (index > -1) {
          state.brokerData.brokerOptions[index].state = obj.value
            ? obj.value.toUpperCase()
            : "NO";
        } else {
          state.brokerData.brokerOptions.push({
            id: obj.id,
            optionId: obj.optionId,
            state: obj.value ? obj.value.toUpperCase() : "NO",
          });
        }
      } else if (obj.type === "brokerVipOption") {
        let index = state.brokerData.brokerVipConditionOptions.findIndex(
          (item) => parseInt(item.id) === parseInt(obj.id)
        );
        if (index > -1) {
          state.brokerData.brokerVipConditionOptions[index].state = obj.value
            ? obj.value.toUpperCase()
            : "NO";
        } else {
          state.brokerData.brokerVipConditionOptions.push({
            id: obj.id,
            optionId: obj.optionId,
            state: obj.value ? obj.value.toUpperCase() : "NO",
          });
        }
      } else if (obj.type === "demoPlatform") {
        let index = state.brokerData.brokerDemoPlatforms.findIndex(
          (item) => parseInt(item.id) === parseInt(obj.id)
        );
        if (obj.id === null && obj.demoTradingPlatformId !== "" && index < 0) {
          let itemToDelete = state.brokerData.brokerDemoPlatforms.findIndex(
            (item) => item.demoTradingPlatformId === obj.demoTradingPlatformId
          );
          state.brokerData.brokerDemoPlatforms.splice(itemToDelete, 1);
        } else if (index < 0 && obj.demoTradingPlatformId !== "") {
          state.brokerData.brokerDemoPlatforms.push({
            demoTradingPlatformCondition: obj.demoTradingPlatformCondition,
            demoTradingPlatformConditionDays: isNaN(
              parseInt(obj.demoTradingPlatformConditionDays)
            )
              ? null
              : parseInt(obj.demoTradingPlatformConditionDays),
            demoTradingPlatformId: parseInt(obj.demoTradingPlatformId),
            id: obj.id ? obj.id : null,
          });
        } else if (index >= 0 && obj.demoTradingPlatformId !== "") {
          state.brokerData.brokerDemoPlatforms[
            index
          ].demoTradingPlatformCondition = obj.demoTradingPlatformCondition;
          state.brokerData.brokerDemoPlatforms[
            index
          ].demoTradingPlatformConditionDays = isNaN(
            parseInt(obj.demoTradingPlatformConditionDays)
          )
            ? null
            : parseInt(obj.demoTradingPlatformConditionDays);
          state.brokerData.brokerDemoPlatforms[index].demoTradingPlatformId =
            obj.demoTradingPlatformId;
        } else if (index >= 0 && obj.demoTradingPlatformId === "") {
          Vue.delete(state.brokerData.brokerDemoPlatforms, index);
        }
      } else if (obj.type === "companyBlock") {
        let index = state.brokerData.brokerCompanies.findIndex(
          (item) => item.id === obj.id
        );
        if (index >= 0) {
          if (obj.name !== "" && obj.name !== undefined) {
            state.brokerData.brokerCompanies[index].name = obj.name;
            state.brokerData.brokerCompanies[index].ticker = obj.ticker;
            state.brokerData.brokerCompanies[index].linkTicker = obj.linkTicker;
          } else {
            state.brokerData.brokerCompanies.splice(index, 1);
          }
        } else {
          state.brokerData.brokerCompanies.push({
            name: obj.name,
            ticker: obj.ticker,
            linkTicker: obj.linkTicker,
            id: null,
            languageSlug: obj.languageSlug,
          });
        }
      } else {
        if (obj.name)
          if (obj.name !== "" && _.has(state.brokerData, obj.name)) {
            state.brokerData[obj.name] = obj.value;
          }
      }
    },
    removingEmptyDemoPlatforms(state) {
      Vue.delete(
        state.brokerData.brokerDemoPlatforms,
        state.brokerData.brokerDemoPlatforms.findIndex(
          (item) => item.demoTradingPlatformId === ""
        )
      );
    },
    resetingBroker(state) {
      state.brokerData = null;
      state.brokerData = {
        anchor: "",
        mainData: [],
        foundedYear: null,
        onlineSinceYear: null,
        contactsForAdmin: "",
        currencyPairs: "",
        ibBroker: "",
        vpsState: "NO",
        vpsValue: null,
        serverTimezone: "",
        stopOrdersRebates: "",
        limitOrdersRebates: "",
        allOrdersRebates: "",
        ebBroker: "",
        brokerLicenses: [],
        paymentOptions: [],
        brokerCountryOffices: [],
        accountCurrencies: [],
        websiteLanguages: [],
        supportLanguages: [],
        supportMethods: [],
        brokerHistory: [],
        brokerOptions: [],
        brokerDemoPlatforms: [],
        brokerVipConditions: [],
        brokerVipConditionOptions: [],
        reviewed: "NONE",
        acceptsUsClients: "NO",
        freeEducation: "NO",
        brokerWarningSings: [],
        brokerNotes: [],
        scam: false,
        autochartist: false,
        bully: false,
        brokerCompanies: [],
        allowedCountries: [],
        prohibitedCountries: [],
        brokerFlags: [],
        noCreateScreenshot: false,
      };
    },
    removingTypeNames(state) {
      let data = state.brokerReviewed;

      if (data != undefined) {
        delete data.__typename;

        for (var key in data) {
          if (typeof data[key] == "object" && data[key] != null) {
            data[key].forEach((item) => {
              delete item.__typename;
            });
          }
        }
      }
    },
    cleaningHistory(state) {
      state.brokerData.brokerHistory.forEach((item) => {
        let invalid = item.data.findIndex(
          (historyItem) =>
            historyItem.date === "" ||
            historyItem.text === "" ||
            historyItem.text === undefined ||
            historyItem.date === undefined
        );
        if (invalid > -1) {
          item.data.splice(invalid, 1);
        }
      });
    },
    removingEmptyLicenses(state) {
      state.brokerData.brokerLicenses = state.brokerData.brokerLicenses.filter(
        (e) => e.licenseNumber !== null && e.licenseNumber !== ""
      );
    },
    preparingDataForReview(state) {
      Vue.delete(state.brokerData, "__typename");
      state.brokerData.vpsValue = isNaN(parseInt(state.brokerData.vpsValue))
        ? null
        : parseInt(state.brokerData.vpsValue);
      state.brokerData.foundedYear = isNaN(
        parseInt(state.brokerData.foundedYear)
      )
        ? null
        : parseInt(state.brokerData.foundedYear);
      state.brokerData.onlineSinceYear = isNaN(
        parseInt(state.brokerData.onlineSinceYear)
      )
        ? null
        : parseInt(state.brokerData.onlineSinceYear);
      state.brokerData.brokerVipConditions.forEach((item) => {
        Vue.delete(item, "__typename");
      });
      state.brokerData.mainData.forEach((item) => {
        Vue.delete(item, "__typename");
      });
      state.brokerData.brokerDemoPlatforms.forEach((item) => {
        delete item.__typename;
        delete item.demoTradingPlatformName;
        isNaN(item.id) ? delete item.id : "";
        item.demoTradingPlatformCondition =
          item.demoTradingPlatformCondition !== ""
            ? item.demoTradingPlatformCondition.toUpperCase()
            : "YES";
      });

      state.brokerData.brokerHistory.forEach((item) => {
        Vue.delete(item, "__typename");
        item.data.forEach((historyItem) => {
          Vue.delete(historyItem, "__typename");
        });
      });
      state.brokerData.brokerLicenses.forEach((item) => {
        Vue.delete(item, "licenseName");
        Vue.delete(item, "__typename");
      });
      state.brokerData.freeEducation = state.brokerData.freeEducation
        ? state.brokerData.freeEducation.toUpperCase()
        : "NO";
      state.brokerData.acceptsUsClients = state.brokerData.acceptsUsClients
        ? state.brokerData.acceptsUsClients.toUpperCase()
        : "NO";
      state.brokerData.vpsState = state.brokerData.vpsState
        ? state.brokerData.vpsState.toUpperCase()
        : "NO";
      state.brokerData.serverTimezone = state.brokerData.serverTimezone
        ? state.brokerData.serverTimezone
        : "GMT";
      state.brokerData.brokerVipConditionOptions.forEach((item) => {
        item.state
          ? (item.state = item.state.toUpperCase())
          : item.optionState
          ? (item.state = item.optionState.toUpperCase())
          : (item.state = "NO");
        Vue.delete(item, "optionState");
        Vue.delete(item, "__typename");
        Vue.delete(item, "optionName");
      });
      state.brokerData.brokerOptions.forEach((item) => {
        item.state
          ? (item.state = item.state.toUpperCase())
          : item.optionState
          ? (item.state = item.optionState.toUpperCase())
          : (item.state = "NO");

        Vue.delete(item, "optionState");
        Vue.delete(item, "__typename");
        Vue.delete(item, "optionName");
      });
      state.brokerData.supportMethods = state.brokerData.supportMethods
        ? state.brokerData.supportMethods.map((item) =>
            typeof item === "object"
              ? (item = parseInt(item.id))
              : (item = parseInt(item))
          )
        : [];
      state.brokerData.supportLanguages = state.brokerData.supportLanguages
        ? state.brokerData.supportLanguages.map((item) =>
            typeof item === "object"
              ? (item = parseInt(item.id))
              : (item = parseInt(item))
          )
        : [];
      state.brokerData.websiteLanguages = state.brokerData.websiteLanguages
        ? state.brokerData.websiteLanguages.map((item) =>
            typeof item === "object"
              ? (item = parseInt(item.id))
              : (item = parseInt(item))
          )
        : [];
      state.brokerData.accountCurrencies = state.brokerData.accountCurrencies
        ? state.brokerData.accountCurrencies.map((item) =>
            typeof item === "object"
              ? (item = parseInt(item.id))
              : (item = parseInt(item))
          )
        : [];

      state.brokerData.paymentOptions = state.brokerData.paymentOptions
        ? state.brokerData.paymentOptions.map((item) =>
            typeof item === "object"
              ? (item = parseInt(item.id))
              : (item = parseInt(item))
          )
        : [];
      state.brokerData.ebBroker =
        Array.isArray(state.brokerData.ebBroker) &&
        !state.brokerData.ebBroker.length
          ? ""
          : state.brokerData.ebBroker;
      state.brokerData.ibBroker =
        Array.isArray(state.brokerData.ibBroker) &&
        !state.brokerData.ibBroker.length
          ? ""
          : state.brokerData.ibBroker;

      state.brokerData.bully =
        typeof state.brokerData.bully === "boolean"
          ? state.brokerData.bully
          : state.brokerData.bully === "true";
      state.brokerData.autochartist =
        typeof state.brokerData.autochartist === "boolean"
          ? state.brokerData.autochartist
          : state.brokerData.autochartist === "true";
      state.brokerData.scam =
        typeof state.brokerData.scam === "boolean"
          ? state.brokerData.scam
          : state.brokerData.scam === "true";
      state.brokerData.noCreateScreenshot =
        typeof state.brokerData.noCreateScreenshot === "boolean"
          ? state.brokerData.noCreateScreenshot
          : state.brokerData.noCreateScreenshot === "true";

      state.brokerData.brokerCompanies.forEach((item) => {
        isNaN(item.id) ? delete item.id : "";
      });
    },
    settingReviewField(state, data) {
      state.brokerData.reviewed = data;
    },
    setDisabledLanguage(state, data) {
      let index = state.disabledLanguages.findIndex(
        (item) => item.languageSlug === data.languageSlug
      );
      index >= 0
        ? (state.disabledLanguages[index].disabled = data.disabled)
        : state.disabledLanguages.push(data);
    },
    clearDisabledLanguages(state) {
      state.disabledLanguages = [];
    },
    removeCreatedAt(state) {
      delete state.brokerData.createdAt;
    },
    removeUpdatedAt(state) {
      delete state.brokerData.updatedAt;
    },
    removeFavicon(state) {
      delete state.brokerData.favicon;
    },
    resetCountyOffices(state, isAllowed) {
      if (isAllowed) {
        state.brokerData.brokerCountryOffices =
          state.brokerData.brokerCountryOffices.filter(
            (item) => item.status === "PROHIBITED"
          );
      } else {
        state.brokerData.brokerCountryOffices =
          state.brokerData.brokerCountryOffices.filter(
            (item) => item.status === "ALLOWED"
          );
      }
    },
    updateCountryOffices(state, data) {
      let index = state.brokerData.brokerCountryOffices.findIndex(
        (office) => +office.countryOfficeId === +data.countryOfficeId
      );
      if (index >= 0) {
        state.brokerData.brokerCountryOffices[index].status = data.status;
      } else {
        !state.brokerData.prohibitedCountries
          ? (state.brokerData.prohibitedCountries = [])
          : null;
        !state.brokerData.allowedCountries
          ? (state.brokerData.allowedCountries = [])
          : null;
        if (data.status === "PROHIBITED") {
          state.brokerData.prohibitedCountries.push({
            ...data,
            id: data.countryOfficeId,
          });
        } else {
          state.brokerData.allowedCountries.push({
            ...data,
            id: data.countryOfficeId,
          });
        }
        state.brokerData.brokerCountryOffices.push({
          ...data,
          id: data.countryOfficeId,
        });
      }
    },
    touchCountryOffices(state, data) {
      state.countryOfficesChanged = data;
    },
    updateBrokerNotes(state, data) {
      if (!state.brokerData.brokerNotes) {
        state.brokerData.brokerNotes = [];
      }
      if (!data.id) {
        delete data.id;
        state.brokerData.brokerNotes.push(data);
      } else {
        let oldDataIndex = state.brokerData.brokerNotes.findIndex(
          (item) => item.id === data.id
        );
        if (oldDataIndex > -1) {
          state.brokerData.brokerNotes[oldDataIndex].description =
            data.description;
        } else {
          state.brokerData.brokerNotes.push(data);
        }
      }
    },

    updateBrokerWarningSings(state, data) {
      if (!state.brokerData.brokerWarningSings) {
        state.brokerData.brokerWarningSings = [];
      }
      if (!data.id) {
        delete data.id;
        state.brokerData.brokerWarningSings.push(data);
      } else {
        let oldDataIndex = state.brokerData.brokerWarningSings.findIndex(
          (item) => item.id === data.id
        );
        if (oldDataIndex > -1) {
          state.brokerData.brokerWarningSings[oldDataIndex].description =
            data.description;
        } else {
          state.brokerData.brokerWarningSings.push(data);
        }
      }
    },
    updateCustomFlags(state, data) {
      if (!state.brokerData.brokerFlags) {
        state.brokerData.brokerFlags = [];
      }
      if (!data.id) {
        delete data.id;
        state.brokerData.brokerFlags.push(data);
      } else {
        let oldDataIndex = state.brokerData.brokerFlags.findIndex(
          (item) => item.id === data.id
        );
        if (oldDataIndex > -1) {
          state.brokerData.brokerFlags[oldDataIndex].flags = data.flags;
        } else {
          state.brokerData.brokerFlags.push(data);
        }
      }
    },
    SET_CURRENT_BROKER_MAIN_DATA: (state, payload) => {
      state.currentBrokerMainData = payload;
    },
    SET_PARENT_BROKER_MAIN_DATA: (state, payload) => {
      state.parentBrokerMainData = payload;
    },
    SET_RELATED_BROKERS: (state, payload) => {
      state.relatedBrokers = payload;
    },

    createNewCompanyBlock(state, data) {
      state.brokerData.brokerCompanies.push(data);
    },
    removeCompanyBlock(state, obj) {
      let index = state.brokerData.brokerCompanies.findIndex(
        (item) => item.id === obj.id
      );
      if (index !== -1) {
        state.brokerData.brokerCompanies.splice(index, 1);
      }
    },
    setBrokerCompany(state, obj) {
      if (!obj.id) {
        state.brokerData.brokerCompanies.push(obj);
      } else {
        let index = state.brokerData.brokerCompanies.findIndex(
          (item) => item.id === obj.id
        );
        if (index !== -1) {
          state.brokerData.brokerCompanies[index] = obj;
        } else {
          state.brokerData.brokerCompanies.push(obj);
        }
      }
    },
    removingEmptyBrokerCompanies(state) {
      state.brokerData.brokerCompanies =
        state.brokerData.brokerCompanies.filter((item) => item.name !== "");
    },
    setHiddenProhibitedCounties(state, data) {
      state.brokerData.prohibitedCountries = data;
    },
    setHiddenAllowedCounties(state, data) {
      state.brokerData.allowedCountries = data;
    },
    preparingBrokerCountries(state) {
      let arr = [];
      if (state.brokerData.allowedCountries) {
        state.brokerData.allowedCountries.forEach((item) => {
          arr.push({
            id: null,
            countryOfficeId: item,
            status: "ALLOWED",
          });
        });
      }
      if (state.brokerData.prohibitedCountries) {
        state.brokerData.prohibitedCountries.forEach((item) => {
          arr.push({
            id: null,
            countryOfficeId: item,
            status: "PROHIBITED",
          });
        });
      }
      state.brokerData.brokerCountryOffices = arr;
    },
  },
  state: {
    countryOfficesChanged: false,
    currentBrokerMainData: null,
    parentBrokerMainData: null,
    relatedBrokers: [],
    brokerData: {
      anchor: "",
      mainData: [],
      foundedYear: null,
      onlineSinceYear: null,
      contactsForAdmin: "",
      currencyPairs: "",
      ibBroker: "",
      vpsState: "NO",
      vpsValue: null,
      serverTimezone: "",
      stopOrdersRebates: "",
      limitOrdersRebates: "",
      allOrdersRebates: "",
      ebBroker: "",
      brokerLicenses: [],
      paymentOptions: [],
      brokerCountryOffices: [],
      accountCurrencies: [],
      websiteLanguages: [],
      supportLanguages: [],
      supportMethods: [],
      brokerHistory: [],
      brokerOptions: [],
      brokerDemoPlatforms: [],
      brokerVipConditions: [],
      brokerVipConditionOptions: [],
      reviewed: "NONE",
      acceptsUsClients: "NO",
      freeEducation: "NO",
      brokerWarningSings: [],
      brokerNotes: [],
      scam: false,
      autochartist: false,
      bully: false,
      brokerCompanies: [],
      allowedCountries: [],
      prohibitedCountries: [],
      brokerFlags: [],
      noCreateScreenshot: false,
    },
    brokerDefault: {
      anchor: "",
      mainData: [],
      foundedYear: null,
      onlineSinceYear: null,
      contactsForAdmin: "",
      currencyPairs: "",
      ibBroker: "",
      vpsState: "NO",
      vpsValue: null,
      serverTimezone: "",
      stopOrdersRebates: "",
      limitOrdersRebates: "",
      allOrdersRebates: "",
      ebBroker: "",
      brokerLicenses: [],
      paymentOptions: [],
      brokerCountryOffices: [],
      accountCurrencies: [],
      websiteLanguages: [],
      supportLanguages: [],
      supportMethods: [],
      brokerHistory: [],
      brokerOptions: [],
      brokerDemoPlatforms: [],
      brokerVipConditions: [],
      brokerVipConditionOptions: [],
      reviewed: "NONE",
      acceptsUsClients: "NO",
      freeEducation: "NO",
      brokerWarningSings: [],
      brokerNotes: [],
      scam: false,
      autochartist: false,
      bully: false,
      brokerCompanies: [],
      allowedCountries: [],
      prohibitedCountries: [],
      brokerFlags: [],
      noCreateScreenshot: false,
    },
    disabledLanguages: [],
  },
  getters: {
    getBrokerData(state) {
      return state.brokerData;
    },
    getDefaultData(state) {
      return state.brokerDefault;
    },
    getDisabledLanguages(state) {
      return state.disabledLanguages;
    },
    getCountryOfficesChanged(state) {
      return state.countryOfficesChanged;
    },
  },
};
