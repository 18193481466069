import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { createProvider } from "./vue-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";

import store from "./store";
import style from "./assets/scss/style.scss";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);

import VCalendar from "v-calendar";
Vue.use(VCalendar);

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.filter("dateFormat", (str) => {
  let date = new Date(str.replace(" ", "T") + "Z");
  function addZero(num) {
    return parseInt(num) > 9 ? num : "0" + num;
  }
  if (date) {
    return `${date.getFullYear()}.${addZero(date.getMonth() + 1)}.${addZero(
      date.getDate()
    )} ${addZero(date.getHours())}:${addZero(date.getMinutes())}`;
  }
});

Vue.filter("numbersOnly", (str) => {
  return str.toString().replace(/\D/g, "");
});

const cache = new InMemoryCache({
  addTypename: false,
});

new Vue({
  router,
  apolloProvider: createProvider({ cache }),
  store,
  render: (h) => h(App),
  vSelect,
  style,
  VCalendar,
}).$mount("#app");
